import * as t from 'io-ts'

export const vmStyleMap = {
  sunglasshut: 'sgh',
  opsm: 'opsm',
  to: 'to',
  sv: 'sv',
  rb: 'rb',
  oo: 'oo',
  lc: 'lc',
  multi: 'm4c',
} as const

const StyleDecoder = t.union([
  t.literal('sgh'),
  t.literal('opsm'),
  t.literal('to'),
  t.literal('sv'),
  t.literal('rb'),
  t.literal('oo'),
  t.literal('lc'),
  t.literal('m4c'),
])

export const StoreDecoder = t.intersection(
  [
    t.type(
      {
        id: t.string,
        type: StyleDecoder,
      },
      'IntersectStore01'
    ),
    // Optional
    t.partial(
      {
        storeId: t.string,
        name: t.string,
        address: t.string,
        standAlone: t.boolean,
        logo: t.string,
        region: t.string,
        countryId: t.string,
        globalStoreId: t.string,
      },
      'IntersectStore02'
    ),
  ],
  'Store'
)

export type Store = t.TypeOf<typeof StoreDecoder>
